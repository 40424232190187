@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  color: #f9bd22;
  background-color: #383d52;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.App {
  flex: 1 1 auto;
  align-items: stretch;
}

.header {
  background-color: #2c324c;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
